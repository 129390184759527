<template>
    <div class="comments">
        <div v-if="showPage">
            <van-list
                v-model="loading"
                @load="onLoad"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了">
                <Comments :list="goods"/>
            </van-list>
        </div>
        <van-empty image="https://khome2.tuzuu.com/klfront/newlogo.png" description="正在加载..." v-else/>
    </div>
</template>

<script>
import Comments from "@/components/Comments"

export default {
    name: 'comments',
    components: {
        Comments
    },
    data: () => ({
        goods: [],
        loading: false,
        finished: false,
        over: false,
        page: 1,
        showPage: false
    }),
    created() {
        this.getData();
    },
    methods: {
        onLoad() {
            this.getData();
        },
        async getData() {
            if (!this.over) {
                const res = await axios.get("/get_comment", {
                    params: {
                        page: this.page,
                        goods_id: this.$route.params.id,
                    }
                });
                if (res.code == 1) {
                    console.log(res.data)
                    this.showPage = true;
                    if (res.data.length === 0) {
                        this.over = true;
                        this.finished = true;
                        return;
                    }
                    this.loading = false;
                    this.page++;
                    this.goods.push(...res.data);
                } else {
                    this.$toast(res.message);
                }
            }
        },
    }
}
</script>

<style lang="less" scoped>
.van-empty {
    min-height: 100vh;

    /deep/ .van-empty__description {
        color: rgb(142, 205, 88);
        font-size: 15px;
        margin: 0;
    }
}
</style>